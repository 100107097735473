export const hasDoc = (api: PortalApi) => {
  if (!api.documentation || !api.documentation.resources) {
    return false
  }

  for (let r of api.documentation.resources) {
    if (r.type === 'text/markdown' && r.filename === 'index.md') {
      return true
    }
  }
  return false
}

export const hasOpenApi = (api: PortalApi) => {
  return api.openapi
}

export interface PortalApiResource {
  filename: string
  type: string
  url?: string
  content?: string
}

export interface PortalApiDocumentation {
  resources: PortalApiResource[]
}

export interface PortalApi {
  slug: string
  title: string
  description: string
  service?: string
  openapi?: string
  id: string
  lastModified: string
  documentation?: PortalApiDocumentation
}

export interface PortalApis {
  apis: PortalApi[]
}
