import { createRoot } from 'react-dom/client'
import App from './components/app/App'
import ThemeProvider from './components/app/ThemeProvider'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
)

serviceWorker.unregister()
