import { Button, Card, CardActions, CardContent, Container, Grid, Typography } from '@mui/material';
import React from 'react';

import PageHeader from '../../elements/PageHeader';

type LibDef = {
  name: string;
  title: string;
  description: string;
  url: string;
};

type LibCardProps = {
  lib: LibDef;
};

const libs = [
  {
    name: 'kmap-external',
    title: 'EmbeddedKrakenMap',
    description: 'Public library for embedding KrakenMap',
    url: 'https://cdn.krakentools.com/kmap/doc/index.html',
  },
];

const LibCard = (props: LibCardProps) => {
  const { lib } = props;
  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            {lib.title}
          </Typography>
          <Typography variant="body1">{lib.description}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              window.location.href = lib.url;
            }}
          >
            Lib Documentation
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export const LibList = () => {
  return (
    <Container>
      <PageHeader breadcrumbs={[{ title: 'Libs' }]} title="Overview of our public libraries"></PageHeader>
      <Grid container spacing={3}>
        {libs.map((lib, index) => (
          <LibCard lib={lib} key={index} />
        ))}
      </Grid>
    </Container>
  );
};
