import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import '@fontsource/roboto-mono'

interface HtmlContentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.body1,
      '& h1': {
        ...theme.typography.h1,
        fontWeight: '400',
        fontSize: '2.125rem',
        lineHeight: '2.25rem',
      },
      '& h2': {
        ...theme.typography.h2,
        fontWeight: '400',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
      },
      '& h3': {
        ...theme.typography.h4,
        fontWeight: '400',
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
      },
      '& h4': {
        ...theme.typography.h4,
        fontWeight: '400',
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
      },
      '& h5': {
        ...theme.typography.h5,
        fontWeight: '400',
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
      },
      '& h6': {
        ...theme.typography.h6,
        fontWeight: '400',
        fontSize: '1.25rem',
        lineHeight: '1.25rem',
      },
      '& p': {
        ...theme.typography.body1,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        color: 'rgba(255, 255, 255, 0.7)',
      },
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main + ' !important',
        '&:hover': {
          color: theme.palette.primary.main + ' !important',
          textDecoration: 'underline',
        },
        '&:active': {
          color: theme.palette.primary.main + ' !important',
        },
        '&:link': {
          color: theme.palette.primary.main + ' !important',
        },
        '&:visited': {
          color: theme.palette.primary.main + ' !important',
        },
      },
      '& img': {
        borderRadius: '5px 5px',
        maxWidth: '100%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '& table': {
        width: '100%',
        border: '2px solid #444',
        marginBottom: '20px',
        padding: 0,
        '& th': {
          padding: '10px',
        },
        '& tbody': {
          '& tr': {
            '& td': {
              padding: '10px',
              borderTop: '2px solid #444',
            },
            '&:last-child': {
              border: 'none',
            },
          },
        },
      },
      '& blockquote': {
        borderLeft: '2px solid #ccc',
        margin: '1.5em 10px',
        padding: '20px',
        quotes: '"\\201C""\\201D""\\2018""\\2019"',
        '& p': {
          display: 'inline',
        },
        '&::before': {
          color: '#ccc',
          content: 'open-quote',
          fontSize: '4em',
          lineHeight: '0.1em',
          marginRight: '0.25em',
          verticalAlign: '-0.4em',
        },
      },
      '& pre': {
        margin: 0,
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: theme.palette.background.default,
        fontFamily: '"Roboto Mono"',
        fontSize: '0.85rem',
        borderRadius: 8,
        overflow: 'auto',
        maxWidth: 'calc(100vw - 42px - 42px)',
        [theme.breakpoints.down('md')]: {
          fontSize: theme.typography.body2.fontSize,
          maxWidth: 'calc(100vw - 40px - 40px)',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.typography.body2.fontSize,
          maxWidth: 'calc(100vw - 28px - 28px)',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#3B3D43',
        },
      },
      '& code': {
        fontFamily: '"Roboto Mono"',
      },
    },
  })
)

const HtmlContent: React.FC<React.PropsWithChildren<HtmlContentProps>> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default HtmlContent
