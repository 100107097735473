import { Container, Grid } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

import { useApp } from '../../elements/AppContext'
import { useApis } from '../../elements/ApiContext'
import PageHeader from '../../elements/PageHeader'
import ApiCard from './ApiCard'
import { PortalApi, PortalApis } from './Apis'

interface ApiListProps {
  classes: any
}

const createCards = (data: PortalApis, click: any) => {
  return data.apis.map((api: PortalApi) => {
    return <ApiCard api={api} key={api.id} click={click} />
  })
}

const ApiList: React.FC<React.PropsWithChildren<ApiListProps>> = (props) => {
  const { click } = useApp()
  const { data, state } = useApis()

  const renderBlock = () => {
    if (state.isError) {
      return (
        <div>
          <h1>We have a problem</h1>
        </div>
      )
    } else if (!state.isLoaded) {
      return <h1>Loading</h1>
    } else {
      return createCards(data, click)
    }
  }

  return (
    <Container>
      <PageHeader breadcrumbs={[{ title: 'APIs' }]} title="Overview of our APIs"></PageHeader>
      <Grid container spacing={1}>
        {renderBlock()}
      </Grid>
    </Container>
  )
}

const styles = (theme: Theme) => createStyles({})

export default withStyles(styles, { withTheme: true })(ApiList)
