import { Container, Link, Paper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react'

import { ClassesProps } from '../../../core/SharedProps'
import Ship from '../../../resources/grieg2.png'
import PageHeader from '../../elements/PageHeader'
import PagePaper from '../../elements/PagePaper'

const ContactUs: React.FC<React.PropsWithChildren<ClassesProps>> = (props) => {
  const { classes } = props
  return (
    <Container className={classes.root}>
      <PageHeader breadcrumbs={[{ title: 'Contact us' }]} title="How to get in touch" />
      <Paper className={classes.image}></Paper>
      <PagePaper>
        <div>
          <Typography variant="body1">
            For larger projects with external partners we normally will invite you into one of our Slack channels. But
            generally we can be contacted via our support team. Send them an email at{' '}
            <Link href="mailto:support@griegconnect.com">support@griegconnect.com</Link>. They will put you in contact
            with one our tech leads.
          </Typography>
        </div>
        <div className={classes.contact}>
          <Typography variant="body1">
            <strong>Grieg Connect</strong>
            <br />
            Langveien 16
            <br />
            6509 Kristiansund
            <br />
            <br />
            Tel: (+47) 464 40 500
            <br />
            Org.nr.:989 786 857
          </Typography>
        </div>
      </PagePaper>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    image: {
      height: '400px',
      marginBottom: '10px',
      backgroundImage: `url(${Ship})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'cover',
      [theme.breakpoints.down('md')]: {
        height: '150px',
      },
    },
    contact: {
      marginTop: '20px',
    },
  })

export default withStyles(styles, { withTheme: true })(ContactUs)
