import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ClassesProps } from '../../core/SharedProps'
import { AppProvider } from '../elements/AppContext'
import { ApiProvider } from '../elements/ApiContext'
import PageFooter from '../elements/PageFooter'
import MainAppBar from './MainAppBar'
import Routes from './Routes'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App: React.FC<React.PropsWithChildren<ClassesProps>> = (props) => {
  const { classes } = props

  return (
    <BrowserRouter>
      <AppProvider>
        <ApiProvider>
          <div className={classes.root}>
            <MainAppBar />
            <div className={classes.overflower}>
              <main className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Routes />
              </main>
              <PageFooter />
            </div>
          </div>
        </ApiProvider>
      </AppProvider>
    </BrowserRouter>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      html: {
        overflow: 'none',
      },
    },
    overflower: {
      display: 'grid',
      gridTemplateRows: '1fr auto',
      height: '100vh',
      overflow: 'auto',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#3B3D43',
      },
    },
    appBarSpacer: {
      ...theme.mixins.toolbar,
      height: theme.spacing(13),
      [theme.breakpoints.down('lg')]: {
        height: theme.spacing(10),
      },
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(9),
      },
    },
  })

export default withStyles(styles, { withTheme: true })(App)
