import { Marked } from '@ts-stack/markdown'
import { highlight } from 'highlight.js'

Marked.setOptions({
  highlight: (code, lang) => {
    return lang ? highlight(lang, code).value : code;
  },
});

const fixImageRegExp = /(!\[.*\]\()[^\s]*(.*\))/gi;
const stripLeadingRegExp = /^<[^>]*>(.*)<\/.*>/gi;

export const fixImageLink = (
  doc: string | undefined,
  resource: string,
  link: string | undefined,
): string => {
  return doc && link ? doc.replace(fixImageRegExp, "$1" + link + "$2") : "";
};

export const stripLeadingTag = (doc: string | undefined): string => {
  return doc ? doc.replace(stripLeadingRegExp, "$1") : "";
};

export const parse = (source: string): string => {
  return Marked.parse(source);
};
