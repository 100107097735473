import { Paper } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

import HtmlContent from './HtmlContent'

interface PagePaperProps {
  classes: any
  children: any
}

const PagePaper: React.FC<React.PropsWithChildren<PagePaperProps>> = ({ classes, children }) => {
  return (
    <Paper className={classes.root}>
      <HtmlContent>{children}</HtmlContent>
    </Paper>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: '40px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
        padding: '10px',
      },
    },
  })

export default withStyles(styles, { withTheme: true })(PagePaper)
