import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

import { useApp } from './AppContext'

export interface PageBreadcrumb {
  path?: string | undefined
  title: string
}

interface PageHeaderProps {
  classes: any
  title: string
  breadcrumbs: PageBreadcrumb[]
  children?: React.ReactNode
}

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = (props) => {
  const { classes, breadcrumbs, title, children } = props
  const { click } = useApp()

  const createPageBreadcrumbs = () => {
    return breadcrumbs.map((breadcrumb: PageBreadcrumb, index: number) => {
      if (breadcrumb.path === undefined) {
        return (
          <Typography key={'br-breadcrumb-' + index} color="textPrimary">
            {breadcrumb.title}
          </Typography>
        )
      }
      return (
        <Link color="primary" key={'br-breadcrumb-' + index} onClick={click(breadcrumb.path)}>
          {breadcrumb.title}
        </Link>
      )
    })
  }

  const PageBreadcrumbs = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="primary" onClick={click('/')}>
          Home
        </Link>
        {createPageBreadcrumbs()}
      </Breadcrumbs>
    )
  }

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.main}>
        <PageBreadcrumbs />
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid className={classes.childs} item>
        {children}
      </Grid>
    </Grid>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 0,
      marginBottom: '30px',
    },
    main: {
      flexGrow: 1,
    },
    childs: {
      '& button': {
        backgroundColor: '#1E252B',
        color: '#64C8FF',
        padding: '10px',
        paddingLeft: '25px',
        paddingRight: '25px',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })

export default withStyles(styles, { withTheme: true })(PageHeader)
