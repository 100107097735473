import { createTheme } from '@mui/material/styles'

const important = ' !important'

export const colors = {
  brandColorPrimary: '#005aa0',
  brandColorSecondary: '#b4cd00',
  background1: '#f7f7f7',
  defaultBackgroundColor: '#ffffff',
  darkModeBackgroundColor: '#292A2E',
  darkModePaperBackgroundColor: '#3B3D43',
  darkModeAppBarBackgroundColor: '#222326',
  darkModeBrandColorPrimary: '#64C8FF',
  darkModeTextColor1: 'rgba(255, 255, 255, 0.89)',
  darkModeTextColor2: 'rgba(255, 255, 255, 0.80)',
  darkModeTextColor3: 'rgba(255, 255, 255, 0.68)',
  darkModeTextDisabled: 'rgba(255, 255, 255, 0.40)',
  green: '#00A587',
  yellow: '#FFD700',
  gcGreen: '#A3D6A6',
  gcYellow: '#FFDA6A',
  gcRed: '#FF9A7A',
  muiBackgroundColor: '#1E252B',
  muiBackdropColor: 'rgba(0, 0, 0, 0.25)',
  paletteBackground: '#11181E',
}

export const appTheme = () => {
  return createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: colors.darkModeBrandColorPrimary,
      },
      text: {
        primary: colors.darkModeTextColor1,
        secondary: colors.darkModeTextColor2,
        disabled: colors.darkModeTextDisabled,
      },
      secondary: {
        main: colors.darkModeBrandColorPrimary,
      },
      background: {
        default: colors.paletteBackground,
        paper: colors.paletteBackground,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.muiBackgroundColor + important,
            color: colors.darkModeTextColor1 + important,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: colors.muiBackdropColor + important,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: colors.muiBackgroundColor,
            color: colors.darkModeTextColor1,
          },
          elevation2: {
            backgroundColor: colors.muiBackgroundColor + important,
            color: colors.darkModeTextColor1 + important,
          },
          elevation4: {
            backgroundColor: colors.muiBackgroundColor,
            color: colors.darkModeTextColor1,
          },
        },
      },
    },
  })
}
