import { Container, Grid, Paper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

import { ClassesProps } from '../../../core/SharedProps'
import Image from '../../../resources/truck.png'
import { useApp } from '../../elements/AppContext'
import MainCard from './MainCard'

const MainPage: React.FC<React.PropsWithChildren<ClassesProps>> = (props) => {
  const { classes } = props
  const { click } = useApp()

  return (
    <Container className={classes.root}>
      <Paper className={classes.paperTop}>
        <Grid container spacing={0} className={classes.intro}>
          <Grid item xs={12} sm={8} md={7} className={classes.introItem}>
            <Typography variant="h2" gutterBottom>
              Welcome to Grieg&nbsp;Connect!
            </Typography>
            <Typography variant="body2">
              Welcome to Grieg Connect for developers. Here you will find what you need to integrate with our services.
            </Typography>
          </Grid>
          <Grid item xs={false} sm={4} md={5} className={classes.illustration}></Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} className={classes.cards}>
        <Grid item xs={12} md={4}>
          <MainCard title="Introduction" onClick={click('/introduction')}>
            <Typography variant="body1">An introduction to this site and its content.</Typography>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard title="API documentation" onClick={click('/apis')}>
            <Typography variant="body1">Documentation of our public APIs.</Typography>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MainCard title="API Authentication" onClick={click('/authentication')}>
            <Typography variant="body1">Authentication guide for our APIs.</Typography>
          </MainCard>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.boxHeader} gutterBottom>
          About Grieg Connect
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              Grieg Connect is a technology firm providing software services to the maritime sector in the Nordic
              region. The firm, a merger between Seamless and Shiplog, has a dedicated group of employees working to
              create great software solutions.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              The client base spread across all the Nordic countries comprises public and private ports, terminals,
              mixed cargo terminals, container terminals, supply bases, ferries, and public entities like vessel traffic
              management centres.
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="body1">
              Our ambition is to raise the competitiveness of maritime sector by providing innovative and user-friendly
              software. We have a modern, open platform upon which we provide applications and services.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cards: {
      alignItems: 'stretch',
      marginBottom: '30px',
    },
    intro: {
      height: '100%',
      minHeight: '350px',
      padding: 0,
      margin: 0,
      [theme.breakpoints.down('md')]: {
        minHeight: '150px',
      },
    },
    introItem: {
      padding: '20px',
      '& h2': {
        color: '#64C8FF',
      },
      '& p': {
        fontSize: '1em',
        fontWeight: '400',
      },
    },
    illustration: {
      backgroundImage: `url(${Image})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'cover',
      [theme.breakpoints.up('sm')]: {
        backgroundSize: '400px',
        backgroundPositionY: '5px',
      },
      [theme.breakpoints.down('md')]: {
        backgroundSize: '330px',
        backgroundPosition: 'left',
      },
    },
    paper: {
      padding: theme.spacing(3),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      marginBottom: '30px',
    },
    paperTop: {
      marginBottom: '30px',
      padding: theme.spacing(0),
    },
  })

export default withStyles(styles, { withTheme: true })(MainPage)
