import { Card, CardContent, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React from 'react'

interface MainCardProps {
  classes: any
  children: any
  title: string
  onClick: () => void
}

const MainCard: React.FC<React.PropsWithChildren<MainCardProps>> = (props) => {
  const { classes, title, children, onClick } = props

  return (
    <Card className={classes.root} component="div" onClick={onClick}>
      <CardContent className={classes.cardContent}>
        <div className={classes.content}>
          <div className={classes.content1}>
            <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <div>{children}</div>
          </div>
          <div className={classes.content2}>
            <ArrowForwardIosIcon />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#005aa0',
      height: '100%',
      padding: theme.spacing(1),
      '&:hover': {
        background: '#003a66',
      },
    },
    cardContent: {
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexGrow: 1,
    },
    content1: {
      flexGrow: 1,
    },
    content2: {
      width: '25px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })

export default withStyles(styles, { withTheme: true })(MainCard)
