import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useApp } from '../../../components/elements/AppContext'
import { useApis } from '../../../components/elements/ApiContext'
import { fixImageLink } from '../../../core/markdown'
import Markdown from '../../elements/Markdown'
import PageHeader from '../../elements/PageHeader'
import PagePaper from '../../elements/PagePaper'
import { PortalApiDocumentation } from './Apis'

interface ApiDocProps {
  classes?: any
}

const ApiDoc: React.FC<React.PropsWithChildren<ApiDocProps>> = () => {
  let { slug } = useParams<{ slug: string }>()
  const { click } = useApp()
  const { apiBySlug } = useApis()
  const doc = apiBySlug(slug)

  const locateIndex = (doc: PortalApiDocumentation | undefined) => {
    if (!doc) {
      return undefined
    }
    for (let r of doc.resources) {
      if (r.filename === 'index.md') {
        return r
      }
    }
    return undefined
  }

  const filterImages = (doc: PortalApiDocumentation) => {
    return doc.resources.filter((r) => r.type === 'image/jpeg' || r.type === 'image/png')
  }

  const renderError = () => {
    return (
      <Container>
        <PagePaper>
          <Typography variant="h5">Cannot find documentation for {slug}</Typography>
        </PagePaper>
      </Container>
    )
  }

  const renderDoc = () => {
    if (!doc || !doc.documentation) {
      return null
    }
    const index = locateIndex(doc.documentation)
    if (!index) {
      return null
    }
    let content = index.content

    for (let mo of filterImages(doc.documentation)) {
      content = fixImageLink(content, mo.filename, mo.url)
    }

    return (
      <React.Fragment>
        <PagePaper>
          <Markdown source={content} />
        </PagePaper>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {!doc || !doc.documentation || !locateIndex(doc.documentation) ? (
        renderError()
      ) : (
        <Container>
          <PageHeader breadcrumbs={[{ title: 'APIs', path: '/apis' }, { title: 'Documentation' }]} title={doc.title}>
            {doc.openapi && <Button onClick={click('/apis/explorer/' + slug)}>API Explorer</Button>}
          </PageHeader>
          {renderDoc()}
        </Container>
      )}
    </React.Fragment>
  )
}

export default ApiDoc
