import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'

import logo from '../../resources/grieg-connect.svg'
import { useApp } from '../elements/AppContext'
import { useAuth0 } from '@auth0/auth0-react'

interface AppBarProps {
  classes: any
}

const MainAppBar: React.FC<React.PropsWithChildren<AppBarProps>> = (props) => {
  const { classes } = props
  const { click } = useApp()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleItemClick = (path: string) => () => {
    click(path)()
    setDrawerOpen(false)
  }

  const links = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'Introduction',
      link: '/introduction',
    },
    {
      title: 'APIs',
      link: '/apis',
    },
    {
      title: 'Libs',
      link: '/libs',
    },
    {
      title: 'Authentication',
      link: '/authentication',
    },
    {
      title: 'Contact us',
      link: '/contact',
    },
  ]

  const AuthButton = () => {
    const { loginWithRedirect, logout, user, isAuthenticated, isLoading } = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    if (isLoading) {
      return <Typography>Loading…</Typography>
    }

    if (isAuthenticated) {
      return (
        <div className={classes.profileButton}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={<Avatar src={user?.picture} />}
          ></Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Logout</MenuItem>
          </Menu>
        </div>
      )
    }
    return (
      <Button variant="contained" onClick={() => loginWithRedirect()}>
        Log In
      </Button>
    )
  }

  const buttons = (item: boolean) => {
    return (
      <React.Fragment>
        <div className={classes.menuItems}>
          {links.map((l, index) => {
            if (item) {
              return (
                <ListItem button key={'menu-item-' + index} onClick={handleItemClick(l.link)}>
                  <ListItemText primary={l.title} />
                </ListItem>
              )
            }
            if (index > 0) {
              return (
                <Button key={'menu-button-' + index} className={classes.button} color="primary" onClick={click(l.link)}>
                  {l.title}
                </Button>
              )
            }
            return null
          })}
        </div>
        <AuthButton />
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <div onClick={click('/')}>
              <img src={logo} className={classes.appLogo} alt=""></img>
            </div>
          </Typography>
          <div className={classes.appBarSpacer}></div>
          {buttons(false)}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="temporary"
          open={drawerOpen}
          anchor="right"
          onClose={handleDrawerToggle}
        >
          <List>{buttons(true)}</List>
        </Drawer>
      </Hidden>
    </div>
  )
}

const drawerWidth = 240

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      cursor: 'pointer',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appLogo: {
      height: '35px',
      pointerEvents: 'none',
      marginTop: '10px',
    },
    appBarSpacer: {
      flexGrow: 1,
    },
    button: {
      marginLeft: '10px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    menuButton: {
      marginLeft: theme.spacing(2),
      paddingRight: 0,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    menuItems: {
      marginRight: '1em',
    },
    profileButton: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  })

export default withStyles(styles, { withTheme: true })(MainAppBar)
