import React, { useEffect } from 'react'
import Prism from 'prismjs'
import 'prismjs/themes/prism-okaidia.css'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-bash'

interface CodeBlockProps {
  language: 'json' | 'bash'
  code: string
}

const CodeBlock = ({ language, code }: CodeBlockProps) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <pre>
      <code className={`language-${language}`}>{code}</code>
    </pre>
  )
}

export default CodeBlock
