import { Container, Link } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import M2MImg from '../../../resources/m2m.svg'
import CodeBlock from '../../elements/CodeBlock'
import PageHeader from '../../elements/PageHeader'
import PagePaper from '../../elements/PagePaper'

const codeCurlObtainToken = `curl --request POST \\
  --url https://grieg.io/api/oauth/token \\
  --header 'content-type: application/json' \\
  --data '{"client_id":"{{your_client_id}}","client_secret":"{{your_client_secret}}",
            "audience":"griegconnect/api","grant_type":"client_credentials"}'`

const codeJwt = `{"access_token": "eyJhbGciOiJSUzI1NiIs6I…", "token_type": "Bearer", "expires_in": 86390}`

const codeCurlUseToken = `curl --location --request GET 'https://grieg.io/api/example/test' \\
   --header 'Authorization: Bearer {{access_token}}' \\
   --header 'Content-Type: application/json'`

interface Props {
  classes: any
}

const Authentication: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { classes } = props

  return (
    <Container>
      <PageHeader breadcrumbs={[{ title: 'Authentication' }]} title="How to access our APIs" />
      <PagePaper>
        <p>
          The Grieg Connect APIs use the <a href="https://tools.ietf.org/html/rfc6749">OAuth 2.0 standard</a> for
          authentication and authorization. We support the Client Credentials Flow (defined in{' '}
          <a href="https://tools.ietf.org/html/rfc6749#section-4.4">OAuth 2.0 RFC 6749, section 4.4</a>) for
          machine-to-machine (M2M) communication. With the Credential Flow, apps will post a Client ID and a Client
          Secret to our authentication endpoint and retrieve a token. Your client can then access our APIs, by adding
          the token to the Authorization header upon each request.
        </p>
        <p>
          You should implement a local strategy for caching a retrieved token within its lifetime (expires_in), to
          reduce load on our authentication services. Also be aware that tokens do expire, and you must implement a
          strategy for automatic renewal. Luckily, most programming languages and frameworks supports OAuth 2.0 these
          days.
        </p>
        <p>
          How to obtain your Client ID and Secret is described in our{' '}
          <Link component={RouterLink} to="/introduction">
            introduction
          </Link>{' '}
          to this site.
        </p>
        <h4>How it works</h4>
        <img src={M2MImg} className={classes.m2m} alt="" />
        <ol type="1">
          <li>
            Your service posts your Client ID and Client Secret to our token endpoint:&nbsp;
            <a href="https://grieg.io/oauth/token">https://grieg.io/api/oauth/token</a>
          </li>
          <li>The Client ID and Client Secret are checked, and the server responds with an Access Token.</li>
          <li>Your service can then use the Access Token to call our APIs on behalf of itself.</li>
          <li>The API responds with the requested data, given the token is validated as ok.</li>
        </ol>
        <h4>Obtaining your token</h4>
        Here is how you can obtain a token with Curl from the command line:
        <CodeBlock language="bash" code={codeCurlObtainToken} />
        <ul>
          <li>
            <b>We do only support JSON payloads</b> and not x-www-form-urlencoded requests.
          </li>
          <li>
            <i>grant_type</i> and <i>audience</i> are <b>optional</b>, and will default to the values in the example
            above.
          </li>
        </ul>
        <p>Upon a successful token request you will get a JWT Token back.</p>
        <CodeBlock language="json" code={codeJwt} />
        <p>The access_token is what you will add as an Authenticate header when calling our APIs. </p>
        <CodeBlock language="bash" code={codeCurlUseToken} />
        <h4>More resources</h4>
        <ul>
          <li>
            <a href="https://oauth.net/2/">OAuth 2</a>
          </li>
          <li>
            <a href="https://oauth.net/2/grant-types/client-credentials/">OAuth - Client Credentials</a>
          </li>
          <li>
            <a href="https://benohead.com/blog/2018/07/05/oauth-2-0-openid-connect-explained/">
              OpenID Connect Explained
            </a>
          </li>
          <li>
            <a href="https://oauth.net/code/">List of OAuth 2.0 libraries for most languages</a>
          </li>
        </ul>
      </PagePaper>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    codeTab: {
      width: '100%',
    },
    m2m: {
      width: '80%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
    },
  })
export default withStyles(styles, { withTheme: true })(Authentication)
