import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { hasDoc, hasOpenApi, PortalApi } from './Apis'

interface ApiCardProps {
  classes: any
  api: PortalApi
  click: (path: string | undefined) => () => void
}

const ApiCard: React.FC<React.PropsWithChildren<ApiCardProps>> = (props) => {
  const { classes, api, click } = props

  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.root}>
        <CardContent className={classes.leadtext}>
          <Typography variant="h6">{api.title}</Typography>
          <Typography variant="body1">{api.description}</Typography>
        </CardContent>
        <CardActions>
          {hasDoc(api) && (
            <Button size="small" color="primary" variant="outlined" onClick={click('/apis/doc/' + api.slug)}>
              Documentation
            </Button>
          )}
          {hasOpenApi(api) && (
            <Button size="small" color="primary" variant="outlined" onClick={click('/apis/explorer/' + api.slug)}>
              API Explorer
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: '120px',
    },
    header: {
      backgroundColor: '#005aa0',
    },
  })

export default withStyles(styles, { withTheme: true })(ApiCard)
