import React from 'react'

import { parse, stripLeadingTag } from '../../core/markdown'
import HtmlContent from './HtmlContent'

interface MarkdownProps {
  source: string | undefined;
  stripLeading?: boolean;
}

const Markdown: React.FC<React.PropsWithChildren<MarkdownProps>> = ({ source, stripLeading }) => {
  if (!source) {
    return null;
  }

  const parsed = stripLeading ? stripLeadingTag(parse(source)) : parse(source);

  return (
    <HtmlContent>
      <div dangerouslySetInnerHTML={{ __html: parsed }} />
    </HtmlContent>
  );
};

export default Markdown;
