import axios, { AxiosRequestConfig } from 'axios'
import { LinearProgress } from '@mui/material'
import React from 'react'

import { PortalApi, PortalApis } from '../sections/apis/Apis'
import { useAuth0 } from '@auth0/auth0-react'
import { useApp } from './AppContext'

const ApiContext = React.createContext<ApiContextType | undefined>(undefined)
export const useApis = () => React.useContext(ApiContext)!

export const ApiProvider = ({ children }: ApiProviderProps) => {
  const { config } = useApp()
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0()

  const initialState: ApisState = {
    data: {
      apis: [],
    },
    loadState: {
      error: undefined,
      isError: false,
      isLoaded: false,
    },
  }
  const [state, setState] = React.useState<ApisState>(initialState)

  const createHeaders = async () => {
    let cfg: AxiosRequestConfig = {}
    if (isAuthenticated) {
      const token = await getAccessTokenSilently()
      cfg = { ...cfg, headers: { Authorization: `Bearer ${token}` } }
    }
    return cfg
  }

  React.useEffect(() => {
    if (!isLoading) {
      createHeaders().then((cfg) => {
        axios
          .get(config.backendUrl + '/api/devportal/apis/', cfg)
          .then(function (response) {
            let result: PortalApis = response.data
            result.apis.forEach((r: PortalApi) => {
              if (r.openapi?.startsWith('/')) {
                r.openapi = config.backendUrl + r.openapi
              }
            })
            setState({
              data: result as PortalApis,
              loadState: { isLoaded: true, isError: false, error: undefined },
            })
          })
          .catch(function (error) {
            setState({
              ...state,
              loadState: { ...state.loadState, isError: true, error: error },
            })
          })
      })
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const apiBySlug = (slug: string | undefined) => {
    if (slug === undefined || state.data === undefined) {
      return undefined
    }
    let r = undefined
    state.data.apis.forEach((element: any) => {
      if (element.slug === slug) {
        r = element
      }
    })
    return r
  }

  if (!state.loadState.isLoaded) {
    return <LinearProgress />
  }

  return (
    <ApiContext.Provider value={{ data: state.data, state: state.loadState, apiBySlug: apiBySlug }}>
      {children}
    </ApiContext.Provider>
  )
}

interface ApisState {
  data: PortalApis
  loadState: LoadState
}

interface LoadState {
  isLoaded: boolean
  isError: boolean
  error: string | undefined
}

type ApiContextType = {
  data: PortalApis
  state: LoadState
  apiBySlug: (slug: string | undefined) => PortalApi | undefined
}

type ApiProviderProps = {
  children: React.ReactNode
}
