import { Container, Typography } from '@mui/material'
import React from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import PageHeader from '../../elements/PageHeader'
import PagePaper from '../../elements/PagePaper'
import logo from '../../../resources/port-agent-in-office.svg'

interface PageProps {
  classes: any
}

const Introduction = (props: PageProps) => {
  const { classes } = props
  return (
    <Container>
      <PageHeader breadcrumbs={[{ title: 'Introduction' }]} title="Introduction for developers" />
      <PagePaper>
        <Typography variant="h1">Welcome</Typography>
        <p>
          This is a site where we you will find technical documentation of our APIs and services. We have to admit that
          gathering all the documentation we have produced over the years in various ways and formats takes a little bit
          of time, but we are working on it. Down the road, all of our public APIs will be documented here. In the
          meantime, please contact us if you can't find what you are looking for.
        </p>

        <img src={logo} className={classes.appLogo} alt=""></img>
        <h4>About our APIs</h4>
        <p>
          We strongly believe in choosing the right tool for a job. The same goes for our APIs. There is no silver
          bullet format or specification that solves all scenarios well. That said, we stribe to follow common best
          practices and strategies. We choose REST APIs where we can, RPC protocols (gRPC/SOAP) when needed, or custom
          binary formats when every byte over the wire matters. The documentation for each of our APIs will specify
          formats and specs in use. That said, the majority of our APIs are simple REST APIs with json payloads.
        </p>
        <p>
          Common for all of our public APIs is that we use [OAuth
          2.0](https://www.oauth.com/oauth2-servers/access-tokens/client-credentials/) for authentication. If we have
          exceptions to that rule, it will be well-documented within the documentation for the specific API. Click on
          the Authentication link in the top menu to get more details.
        </p>
        <h4>How to gain access</h4>
        <p>
          Access to each of our APIs will be given upon request to our partners and customers. Please contact our
          support team or one of your contacts at Grieg Connect for help.
        </p>
        <h4>How to report errors</h4>
        <p>
          If you experience any problem with our APIs, or find bugs, please report this back to us by sending an email
          to support@griegconnect.com. Our support team will make sure to route the email to the right team. We will
          make sure to investigate the issue and get back to you as soon as possible.
        </p>
      </PagePaper>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    appLogo: {
      height: '400px',
      pointerEvents: 'none',
      marginTop: '10px',
    },
  })

export default withStyles(styles, { withTheme: true })(Introduction)
