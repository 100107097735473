import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { Route as ReactRouterRoute, Routes as ReactRouterRoutes } from 'react-router-dom'

import { useApp } from '../elements/AppContext'
import ApiDoc from '../sections/apis/ApiDoc'
import ApiExplorer from '../sections/apis/ApiExplorer'
import ApiList from '../sections/apis/ApiList'
import Authentication from '../sections/doc/Authentication'
import ContactUs from '../sections/doc/ContactUs'
import Introduction from '../sections/doc/Introduction'
import { LibList } from '../sections/libs/LibList'
import MainPage from '../sections/mainpage/MainPage'
interface RoutesProps {
  classes: any
}

const Routes: React.FC<React.PropsWithChildren<RoutesProps>> = ({ classes }) => {
  const { config } = useApp()

  if (!config.loaded) {
    return <div></div>
  }

  return (
    <ReactRouterRoutes>
      <ReactRouterRoute path="/" element={<MainPage />} />
      <ReactRouterRoute path="/apis/" element={<ApiList />} />
      <ReactRouterRoute path="/apis/explorer/:slug" element={<ApiExplorer />} />
      <ReactRouterRoute path="/apis/doc/:slug" element={<ApiDoc />} />
      <ReactRouterRoute path="/introduction" element={<Introduction />} />
      <ReactRouterRoute path="/authentication" element={<Authentication />} />
      <ReactRouterRoute path="/contact" element={<ContactUs />} />
      <ReactRouterRoute path="/libs" element={<LibList />} />
    </ReactRouterRoutes>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    listTransitionEnter: {
      opacity: 0.01,
    },
    listTransitionEnterActive: {
      opacity: 1,
      transition: 'opacity 200ms ease-in',
    },
    listTransitionExit: {
      opacity: 0.1,
    },
    listTransitionExitActive: {
      opacity: 1,
    },
  })

export default withStyles(styles, { withTheme: true })(Routes)
